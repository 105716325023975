import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { COLORS, BREAKPOINTS } from '../ui/variables';
import Heading4 from '../ui/Heading4';
import AppleAppStoreImage from '../images/apple-app-store.png';
import GoogleAppStoreImage from '../images/google-app-store.png';
import InstagramIco from '../images/icons/instagram.png';
import FacebookIco from '../images/icons/facebook.png';
import LinkedIn from '../images/icons/linkedin.svg';
import TikTok from '../images/icons/tiktok.svg';
import ArrowRightBlackIco from '../images/icons/arrow-right-black.svg';
import NewsletterSubscribe from './forms/NewsletterSubscribe';
import { Uppercase } from '../ui/TypographyStyles';

const Container = styled.footer`
  background: ${COLORS.WHITE};
  padding: 40px 0 0 0;
  border-top: 2px solid ${COLORS.LIGHT_GRAY};
`;

const Content = styled.div`
  max-width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 3em;
  padding: 0 40px;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 1em;
    padding: 0 20px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
  }
`;

const BeigeRounded = styled.div`
  background: ${COLORS.NEW_BEIGE};
  border-radius: 4px;
  padding: 30px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: column;
  }
`;

const Subscribe = styled(BeigeRounded)`
  grid-column-start: 1;
  grid-column-end: 13;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 13;
    justify-content: center;
  }

  @media ${BREAKPOINTS.MOBILE} {
    grid-column-start: 1;
    grid-column-end: 5;
    flex-direction: column;
  }
`;

const OfferLink = styled(Link)`
  background-image: url(${ArrowRightBlackIco});
  background-repeat: no-repeat;
  background-position: right;
  background-size: 16px;
  padding-right: 24px;
`;

const LinkList = styled.ul`
  padding: 0;
  margin: 0 0 60px 0;
  list-style: none;
  font-weight: 400;

  li {
    font-size: 20px;
    margin-bottom: 24px;
  }

  li:first-of-type {
    text-transform: uppercase;
    color: ${COLORS.DARK_GRAY};
    font-size: 14px;
    margin-bottom: 18px;
  }
`;

const DropDownLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.BLACK};
  font-size: 18px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.5px;

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }
`;

const ExternalDropDownLink = styled.a`
  text-decoration: none;
  color: ${COLORS.BLACK};
  font-size: 18px;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 0.5px;

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }
`;

const AdditionalInformation = styled.div`
  max-width: 1240px;
  margin: auto;
  padding: 0 40px;
  padding: 31px 0;
  border-top: 2px solid ${COLORS.LIGHT_GRAY};
  font-size: 12px;
  color: #888;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1240px) {
    padding: 32px 20px;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  a {
    color: #888;
    text-decoration: none;
  }
`;

const AdditionalLinks = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;
    flex-wrap: wrap;
  }

  li {
    margin: 0 32px 0 0;

    @media screen and (max-width: 700px) {
      width: 50%;
      margin: 0 0 16px 0;
    }
  }

  li:last-of-type {
    margin: 0;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Content>
        <Subscribe>
          <Heading4
            css={css`
              margin-bottom: 0;
              @media ${BREAKPOINTS.TABLET} {
                margin-right: 34px;
              }
              @media ${BREAKPOINTS.MOBILE} {
                margin-right: 0;
                margin-bottom: 18px;
              }
            `}
          >
            Get tips and tricks to keep your pet healthy
          </Heading4>

          <NewsletterSubscribe />
        </Subscribe>

        <LinkList
          css={css`
            grid-column-start: 1;
            grid-column-end: 4;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 1;
              grid-column-end: 5;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Services</Uppercase>
          </li>
          <li>
            <DropDownLink to='/services'>All Services</DropDownLink>
          </li>
          <li>
            <DropDownLink to='/services/24-7-telemedicine'>
              24/7 Telemedicine
            </DropDownLink>
          </li>
          <li>
            <DropDownLink to='/services/dental'>Dental Care</DropDownLink>
          </li>
          <li>
            <DropDownLink to='/services/surgery'>Surgery</DropDownLink>
          </li>
          <li>
            <DropDownLink to='/services/spay-neuter'>
              Spays &amp; Neuters
            </DropDownLink>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 4;
            grid-column-end: 7;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 5;
              grid-column-end: 9;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Resources</Uppercase>
          </li>
          <li>
            <DropDownLink to='/learning-center'>Learning Center</DropDownLink>
          </li>
          <li>
            <ExternalDropDownLink href='https://assets.ctfassets.net/82d3r48zq721/22nWe0saMAC9LYTCfAGdHZ/5bfca0e359cef6e710167b5b96c19f53/Small-Door-Veterinary-Dog-Parenting-101.pdf'>
              Dog Parenting 101
            </ExternalDropDownLink>
          </li>
          <li>
            <ExternalDropDownLink href='https://assets.ctfassets.net/82d3r48zq721/39NvV0Tnl6xJvtpWvqwPAc/3c04388da0bba29be9860b8b91d73ca8/Small-Door-Veterinary-Cat-Parenting-101.pdf'>
              Cat Parenting 101
            </ExternalDropDownLink>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 7;
            grid-column-end: 10;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 1;
              grid-column-end: 5;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Company</Uppercase>
          </li>
          <li>
            <DropDownLink to='/team'>Team</DropDownLink>
          </li>
          <li>
            <DropDownLink to='https://smalldoorvet.com/careers'>
              Careers
            </DropDownLink>
          </li>
          <li>
            <DropDownLink to='/accreditations'>Accreditations</DropDownLink>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 10;
            grid-column-end: 13;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 5;
              grid-column-end: 9;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Locations</Uppercase>
          </li>
          <li>
            <DropDownLink to='/locations'>See Locations</DropDownLink>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 1;
            grid-column-end: 4;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 1;
              grid-column-end: 5;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Contact</Uppercase>
          </li>
          <li>
            <ExternalDropDownLink
              href='tel:+1(212) 933-9044'
              css={css`
                padding: 15px 0;
              `}
            >
              +1 (212) 933-9044
            </ExternalDropDownLink>
            <br />
            <ExternalDropDownLink
              href='mailto:hi@smalldoorvet.com'
              target='_blank'
              rel='noreferrer'
              css={css`
                padding: 15px 0;
              `}
            >
              hi@smalldoorvet.com
            </ExternalDropDownLink>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 4;
            grid-column-end: 7;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 5;
              grid-column-end: 9;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Member App</Uppercase>
          </li>
          <li>
            <a
              href='https://apps.apple.com/us/app/small-door/id1479720592'
              target='_blank'
              rel='noreferrer'
              aria-label='Link to download Small Door app at Apple store.'
            >
              <img
                src={AppleAppStoreImage}
                width='139'
                height='40'
                style={{ marginBottom: '10px' }}
                alt="Apple's app store logo"
              />
            </a>
            <br />
            <a
              href='https://play.google.com/store/apps/details?id=com.smalldoor&hl=en_US'
              target='_blank'
              rel='noreferrer'
              aria-label='Link to download Small Door app at Google play store.'
            >
              <img
                src={GoogleAppStoreImage}
                width='139'
                height='41'
                alt='Google Play store logo'
              />
            </a>
          </li>
        </LinkList>

        <LinkList
          css={css`
            grid-column-start: 7;
            grid-column-end: 10;

            @media ${BREAKPOINTS.TABLET} {
              grid-column-start: 1;
              grid-column-end: 5;
            }

            @media ${BREAKPOINTS.MOBILE} {
              grid-column-start: 1;
              grid-column-end: 5;
            }
          `}
        >
          <li>
            <Uppercase>Social</Uppercase>
          </li>
          <li>
            <a
              href='https://www.instagram.com/smalldoorvet/'
              target='_blank'
              rel='noreferrer'
              aria-label="Link to Small Door's Instagram account."
            >
              <img
                src={InstagramIco}
                width='32'
                height='32'
                alt='Instagram logo'
              />
            </a>
            <a
              css={css`
                margin-left: 24px;
              `}
              href='https://www.facebook.com/smalldoorvet/'
              target='_blank'
              rel='noreferrer'
              aria-label="Link to Small Door's Facebook account."
            >
              <img
                src={FacebookIco}
                width='32'
                height='32'
                alt='Facebook logo'
              />
            </a>
            <a
              css={css`
                margin-left: 24px;
              `}
              href='https://www.linkedin.com/company/smalldoor'
              target='_blank'
              rel='noreferrer'
              aria-label="Link to Small Door's LinkedIn account."
            >
              <img src={LinkedIn} width='32' height='32' alt='LinkedIn logo' />
            </a>
            <a
              css={css`
                margin-left: 24px;
              `}
              href='https://www.tiktok.com/@smalldoorvet'
              target='_blank'
              rel='noreferrer'
              aria-label="Link to Small Door's TikTok account."
            >
              <img src={TikTok} width='32' height='32' alt='TikTok logo' />
            </a>
          </li>
        </LinkList>
      </Content>

      <AdditionalInformation>
        <span>© {new Date().getFullYear()} Small Door Inc.</span>

        <AdditionalLinks>
          <li>
            <Link to='/sitemap.xml'>Sitemap</Link>
          </li>
          <li>
            <Link to='/accessibility'>Accessibility</Link>
          </li>
          <li>
            <Link to='/legal/privacy'>Privacy Policy</Link>
          </li>
          <li>
            <Link to='/legal/terms'>Terms of Service</Link>
          </li>
        </AdditionalLinks>
      </AdditionalInformation>
    </Container>
  );
};

export default Footer;
